import { ITrack } from '../common/types';

type Props = {
    track: ITrack;
};

const Track = (props: Props) => {
    const { track } = props;

    return (
        <div className="track">
            <span className="flex gap-[1rem]">
                <h4>{track.trackNumber}</h4>
                <h4>{track.title}</h4>
            </span>
            <p>{track.artist}</p>
            {track.time && <p>{track.time}</p>}
        </div>
    );
};

export default Track;
