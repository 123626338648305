import { useEffect, useState } from 'react';
import { IMix, ITrack } from '../common/types';
import Foldable from './Foldable';
import Track from './Track';

type Props = {
    mix: IMix;
    searchTerm: string;
    folded: boolean;
    setFolded: (folded: boolean) => void;
};

const Mix = (props: Props) => {
    const { mix, searchTerm, folded, setFolded } = props;
    const { tracks } = mix;
    const [filteredTracks, setFilteredTracks] = useState<ITrack[]>(tracks);

    const filter = (track: ITrack) => {
        const containsTrackTitle = track.title
            .toLowerCase()
            .includes(searchTerm.toLowerCase());

        const containsTrackArtist = track.artist
            .toLowerCase()
            .includes(searchTerm.toLowerCase());

        return containsTrackTitle || containsTrackArtist;
    };

    useEffect(() => {
        setFilteredTracks(tracks.filter(filter));
    }, [searchTerm]);

    useEffect(() => {
        if (searchTerm && filteredTracks.length > 0) {
            setFolded(false);
        }
    }, [filteredTracks]);

    return (
        <div className="tracklist-container">
            <Foldable
                title={mix.name}
                link={mix.link}
                isOpen={!folded}
                setIsOpen={(b) => {
                    setFolded(!b);
                }}
            >
                <ul className="tracklist">
                    {tracks.filter(filter).map((track, i) => {
                        return <Track key={i} track={track} />;
                    })}
                </ul>
            </Foldable>
        </div>
    );
};

export default Mix;
