type Props = {
    value: string;
    onChange: (value: string) => void;
};

const SearchBar = (props: Props) => {
    const { value, onChange } = props;

    return (
        <div>
            <input
                type="text"
                placeholder="Search..."
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;
