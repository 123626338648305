import React from 'react';
import ToggleFoldIcon from './icons/ToggleFoldIcon';

type Props = {
    title: string;
    link: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    children: React.ReactNode;
};

const Foldable = (props: Props) => {
    const { title, link, isOpen, setIsOpen, children } = props;

    return (
        <div className="foldable">
            <span>
                <h2>
                    <a href={link}>{title}</a>
                </h2>
                <ToggleFoldIcon
                    isFolded={isOpen}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </span>
            {isOpen && children}
        </div>
    );
};

export default Foldable;
