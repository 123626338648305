import { useState } from 'react';
import { IMix } from '../common/types';
import trackData from '../data/all.json';
import Mix from './Mix';
import SearchBar from './SearchBar';
import FoldAllIcon from './icons/FoldAllIcon';

const Mixes = () => {
    const [mixes, _] = useState<IMix[]>(trackData as IMix[]);
    const [search, setSearch] = useState<string>('');
    const [folded, setFolded] = useState<boolean[]>(trackData.map(() => true));

    const filteredMixes: IMix[] = (trackData as IMix[]).map((mix) => {
        return {
            ...mix,
            tracks: mix.tracks.filter((track) => {
                const containsTrackTitle = track.title
                    .toLowerCase()
                    .includes(search.toLowerCase());

                const containsTrackArtist = track.artist
                    .toLowerCase()
                    .includes(search.toLowerCase());

                return containsTrackTitle || containsTrackArtist;
            })
        };
    });

    const foldAll = () => {
        setFolded(trackData.map(() => true));
    };

    const setFold = (index: number, value: boolean) => {
        const newFolded = [...folded];
        newFolded[index] = value;
        setFolded(newFolded);
    };

    return (
        <div className="mix-list">
            <header className="mixes-header">
                <h1>Mixes</h1>
                <span>
                    <FoldAllIcon onClick={foldAll} />
                    <SearchBar value={search} onChange={setSearch} />
                </span>
            </header>
            {filteredMixes.map((mix, i) => {
                if (mix.tracks.length === 0) {
                    return <></>;
                }

                return (
                    <Mix
                        key={i}
                        mix={mix}
                        searchTerm={search}
                        folded={folded[i]}
                        setFolded={(b) => setFold(i, b)}
                    />
                );
            })}
        </div>
    );
};

export default Mixes;
